$(function () {
    var $input_usuario = $('#inputextlog1');
    var $input_senha = $('#inputextlog2');

    $input_usuario.on('keypress', function (e) {
        if (!$input_senha.val() && e.which === 13) {
            $input_senha.focus();
            return false;
        }
    });
});
